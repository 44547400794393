import {forwardRef, useRef} from 'react';
import Grid from '@mui/material/Grid';
import EditableText from '../Editables/EditableText'

const Headline = forwardRef((props, ref) =>{

  const editText = useRef();

  return(
    <Grid container className='centered' style={{flexDirection:"column"}}>
      <Grid style={{maxWidth: '80vw'}}>
        <EditableText 
          ref={editText} 
          id={props.id} 
          admin={props.admin}
          variant={"h1"}
          variable={"head_text"}
          text={props.head_text}
        >
          {props.head_text}
        </EditableText>
      </Grid>
    </Grid> 
  )
});

export default Headline;