import "./TestPage.css"
import { useState } from 'react'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {  useQuery, gql  } from '@apollo/client';

//layouts
import Header from "../Components/Layouts/Header"
import Menu from "../Components/Layouts/Menu"

const Pages = gql`
  query MyQuery {
    Pages(where: {id: {_eq: "10"}}) {
      id
      head_text
      head_img
      menu_title
      menu_description
      menu_img0
      menu_img1
      menu_img2
      menu_img3
    }
}`;

function TestPage(){

  const [admin, setAdmin] = useState(false);
  const [PageData, setPageData] = useState({})

  useQuery(Pages, {
    onCompleted: (data) => setPageData(data.Pages[0]),
    onError:(data) => console.log(data)
  });

  return(
    <Grid container>
      <Button variant={"contained"} onClick={() => setAdmin(!admin)}>Admin</Button>
      <Header PageData={PageData} admin={admin}/>
      <Menu PageData={PageData} admin={admin}/>
    </Grid >
  )
}
export default TestPage;