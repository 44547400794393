import {useRef, forwardRef} from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

const  Submit = forwardRef((props, ref) =>{
  const gridRef = useRef();
  return(
    <Grid ref={gridRef}  item m={0.5}>
      <IconButton 
        style={{
          backgroundColor:"white",
          borderRadius: "5px"
        }} 
        variant={"outlined"} 
        onClick={props.submit}
        size={"small"}
      >{props.children}
      </IconButton>
    </Grid>
  )
});

export default Submit;