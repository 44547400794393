import Grid from '@mui/material/Grid';
import TextInfo from './TextInfo'
import Img4Info from './Img4Info';

function Menu(props){

  return(
    <Grid item xs={12} className="centered" style={{height:"50vh"}}>
      <Grid item xs={6} >
        <Img4Info img0={props.PageData.head_img} admin={props.admin}></Img4Info>
      </Grid>
      <Grid item xs={6}>
        <TextInfo title={props.PageData.menu_title} description={props.PageData.menu_description} admin={props.admin} PageData={props.PageData}></TextInfo>
      </Grid>
    </Grid>
  )
}

export default Menu;