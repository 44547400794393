import {forwardRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import { useImperativeHandle } from 'react';

const  EditBox = forwardRef((props, ref) =>{

  const [visible, setVisible] = useState(true);

  useImperativeHandle(ref, () => ({
    setVisibility: (enabled) => {
      setVisible(enabled)
    },
  }));

  return(
    <>
      { 
        props.admin && visible?
          <Grid className="centered" m={0.5}>
            <Grid 
              item 
              p={0.2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection:'row',
                backgroundColor: 'rgba(0,0,0,0.6)',
                borderRadius: "5px",
                width:"fit-content"
              }}
            >
              {props.children}
            </Grid>
          </Grid>
        : null
    }
   </>
  )
});

export default EditBox;