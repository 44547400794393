import { useState } from 'react'
import {  Routes, Route} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink  } from '@apollo/client';
import TestPage from './Pages/TestPage'

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: 'https://risto.hasura.app/v1/graphql',
      headers: {
        'x-hasura-admin-secret':'APn8SUj07ESFjGzomlxAe8nepaSDKu2zoeqfgVZr6Ar3kcFmYEO16dsEO8Z3Znbd'
      }
    }),
    cache: new InMemoryCache(),
  });
};

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2.25rem',
      '@media (max-width:600px)': {
        fontSize: '1.625rem',
      },
    },
    h2: {
      fontSize: '1.75rem',
      '@media (max-width:600px)': {
        fontSize: '1.375rem',
      },
    },
    h3: {
      fontSize: '1.125rem',
      '@media (max-width:600px)': {
        fontSize: '1.125rem',
      },
    },
    h4: {
      fontSize: '1rem',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    h5: {
      fontSize: '0.75rem',
      '@media (max-width:600px)': {
        fontSize: '0.75rem',
      },
    }
  },
  palette: {

    primary: { main: "#000000"},
    secondary: { main: "#14213d" },
    tertiary: { main: "#fca311" },
    quaternary: { main: "#e5e5e5" },
    quinary: { main: "#ffffff" },

  },
});

function App() {
  const [client] = useState(createApolloClient());
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<TestPage />}></Route>
        </Routes>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
