import { useRef,forwardRef } from 'react'
import axios from "axios";
import Grid from '@mui/material/Grid';

import AddTaskIcon from '@mui/icons-material/AddTask';
import HistoryIcon from '@mui/icons-material/History';

import Submit from '../Buttons/Submit'
import Upload from "../Buttons/Upload"
import EditBox from "../Layouts/EditBox"

const EditableImage = forwardRef((props, ref) =>{

  const background = useRef();
  const data  = useRef({
    img:undefined,
  })

  const loadImage=(event) =>{
    const tgt = event.target
    const files = tgt.files;

    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        data.current.img = fr.result;
        background.current.style.backgroundImage = `url(${data.current.img})`
      }
      fr.readAsDataURL(files[0]);
    }
  }

  const postImage = () =>{
    axios.post("post",{
      file:data.current.img,
      url:props.img
    }).then(()=>{
    })
  }

  const submit = () =>{
    postImage();
  }
  const revert = () =>{
    background.current.style.backgroundImage = `url(${props.img+"?"+Date.now()})`
  }

  return(
    <Grid>
      <EditBox admin={props.admin} >
        <Submit submit={()=>revert()}><HistoryIcon  fontSize={"small"}/></Submit>
        <Submit submit={()=>submit()}><AddTaskIcon fontSize={"small"}/></Submit> 
        <Upload submit={(e)=>loadImage(e)}/>
      </EditBox>
      <div 
        className="image centered" 
        ref={background}
        style={{
          display:"flex", 
          alignItems:"center",
          height:props.height,
          width:props.width,
          flexDirection:"column",
          backgroundImage : `url(${props.img+"?"+Date.now()})`
        }}
      >
        {props.children}
      </div>
    </Grid>
  )
});

export default EditableImage;