import { useRef } from 'react'

import Grid from '@mui/material/Grid';

//text components
import Headline from './Headline'
import EditableImage from '../Editables/EditableImage';

function Header(props){

  const editableImageRef = useRef();
  return(
    props.PageData.head_img?
      <Grid item xs={12}>
        <EditableImage width={""} height={"100vh"} img={props.PageData.head_img} ref={editableImageRef} admin={props.admin}>
          <Headline head_text={props.PageData.head_text} id={props.PageData.id} admin={props.admin}/> 
        </EditableImage>
      </Grid>
    : null
  )
}

export default Header;