import { useState,useRef,useImperativeHandle, forwardRef } from 'react'
import { useMutation, gql  } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddTaskIcon from '@mui/icons-material/AddTask';
import HistoryIcon from '@mui/icons-material/History';

//text components
import Submit from '../Buttons/Submit'
import EditBox from "../Layouts/EditBox"

const EditableText = forwardRef((props, ref) =>{

  const [data, setData] = useState({text:undefined})
  const textRef = useRef();

  const RUN_MUTATION = gql`
  mutation MyMutation(
    $id:bigint!
    $text: String!
    ) {
    update_Pages(where: {id: {_eq: $id}}, _set: {${props.variable}: $text}) {
      affected_rows
    }
  }`;

  const runMutation = () => {
    mutate({
      variables: {
        id:props.id,
        text: data.text,
      },
    });
  };

  const [mutate] = useMutation(RUN_MUTATION);

  useImperativeHandle(ref, () => ({
    setMutation: () => {
      if(data.text) runMutation();
    },
    setText:(text)=>{
      textRef.current.innerHTML = text
    }
  }));

  const onEdit = (e) =>{
    setData({
      text: e.target.innerHTML
    })
  }

  const submit = () =>{
    if(data.text) runMutation();
  }

  const revert = () =>{
    textRef.current.innerHTML = props.text
  }

  return(
    <Grid>
      <EditBox admin={props.admin}>
        <Submit submit={revert} ><HistoryIcon  fontSize={"small"}/></Submit>
        <Submit submit={submit} ><AddTaskIcon fontSize={"small"}/></Submit> 
      </EditBox>
      <Typography 
        style={{
          textAlign: 'center',
        }}
        variant={props.variant}
        ref={textRef}
        contentEditable={props.admin}
        suppressContentEditableWarning={true}
        onInput={
          props.admin? (e) =>
            onEdit(e)
          :null
        }
      >  
      {props.children}
      </Typography>
    </Grid> 
  )
});

export default EditableText;