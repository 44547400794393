import { useRef } from 'react'
import Grid from '@mui/material/Grid';
import EditableText from '../Editables/EditableText'

function TextInfo(props){

  const editText = useRef();
  const editText1 = useRef();

  return(
    <Grid container className='centered' >
      <Grid style={{ maxWidth:"80%"}}>
        <EditableText 
          id={props.PageData.id} 
          admin={props.admin}
          variant={"h1"}
          ref={editText} 
          variable={"menu_title"}
          text={props.title}
        >
          {props.title}
        </EditableText>
        <br></br>
        <EditableText 
          id={props.PageData.id} 
          admin={props.admin}
          variant={"subtitle2"}
          ref={editText1} 
          variable={"menu_description"}
          text={props.description}
        >
          {props.description}
        </EditableText>
      </Grid>
    </Grid>
  )
}

export default TextInfo;