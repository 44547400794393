import Grid from '@mui/material/Grid';
import EditableImage from '../Editables/EditableImage';

function Img4Info(props){
  console.log(props)

  return(
    <Grid container className='centered' >
      <Grid style={{ maxWidth:"80%"}} className='centered' p={1}>
        <Grid p={1}>
          {props.img0 ? <EditableImage width={"20vw"} height={"20vh"} img={props.img0} admin={props.admin}/>: null}
        </Grid>  
        <Grid p={1}>
          {props.img0 ? <EditableImage width={"20vw"} height={"20vh"} img={props.img0} admin={props.admin}/>: null}
        </Grid>  
      </Grid>
      <Grid style={{ maxWidth:"80%"}} className='centered'>
        <Grid p={1}>
          {props.img0 ? <EditableImage width={"20vw"} height={"20vh"} img={props.img0} admin={props.admin}/>: null}
        </Grid>  
        <Grid p={1}>
          {props.img0 ? <EditableImage width={"20vw"} height={"20vh"} img={props.img0} admin={props.admin}/>: null}
        </Grid>  
      </Grid>
    </Grid>
  )
}

export default Img4Info;

