import {forwardRef} from 'react';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';

const  Upload = forwardRef((props, ref) =>{
  return(
    <Grid item m={0.5}>
      <Box 
        style={{
          backgroundColor:"white",
          borderRadius: "5px",
        }} 
        variant={"outlined"} 
        onClick={props.submit}
        size={"small"}
        pl={0.5}
        pr={0.5}
      >
        <form method="post" style={{ cursor: "pointer"}} > 
          <label >
            <CloudUploadIcon style={{ cursor: "pointer"}}/>
            <input type="file" id="myFile" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => props.submit(e)}/>
          </label>
        </form>
      </Box> 
    </Grid>
  )
});

export default Upload;


